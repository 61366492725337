<!-- eslint-disable vue/no-v-html -->
<template xmlns:b-row="http://www.w3.org/1999/html">
  <div class="user-list-audits">
    <div
      v-if="isLoadingAudit"
      class="text-center"
    >
      <b-spinner
        label="Carregando..."
      />
    </div>
    <div v-else>
      <b-table
        small
        responsive
        :items="auditTableItems"
        :fields="auditTableFields"
        show-empty
        class=""
      >
        <template #empty>
          <h4>Nenhuma alteração identificada</h4>
        </template>

        <template #head(field)>
          Campo Alterado
        </template>

        <template #head(old)>
          Valor Antigo
        </template>

        <template #head(new)>
          Valor Novo
        </template>

        <template #cell(field)="auditRow">
          {{ $t(`audits.${auditRow.item.type}.${auditRow.item.field}`) | ucfirst }}
        </template>

        <template #cell(old)="auditRow">
          <div
            v-if="auditRow.item.field === 'preferred_zones'"
            class="pills"
          >
            <zone-pills :zones="auditRow.item.old" />
          </div>
          <div
            v-else-if="auditRow.item.field === 'preferred_segments' || auditRow.item.field === 'related_segments'"
            class="pills"
          >
            <segment-pills :segments="auditRow.item.old" />
          </div>
          <p
            v-else
            v-html="auditRow.item.old instanceof Array ? auditRow.item.old.join('<br>') : auditRow.item.old"
          />
        </template>

        <template #cell(new)="auditRow">
          <div
            v-if="auditRow.item.field === 'preferred_zones'"
            class="pills"
          >
            <zone-pills :zones="auditRow.item.new" />
          </div>
          <div
            v-else-if="auditRow.item.field === 'preferred_segments' || auditRow.item.field === 'related_segments'"
            class="pills"
          >
            <segment-pills :segments="auditRow.item.new" />
          </div>
          <p
            v-else
            v-html="auditRow.item.new instanceof Array ? auditRow.item.new.join('<br>') : auditRow.item.new"
          />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/userService'
import { formatDateTimeDbToView } from '@/utils/helpers'
import ZonePills from '@/views/cms/components/ZonePills.vue'
import SegmentPills from '@/views/cms/components/SegmentPills.vue'
import {
  BTable,
  BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import requestErrorMessage from '../../../mixins/requestErrorMessage'

export default {
  components: {
    BTable,
    BSpinner,
    ZonePills,
    SegmentPills,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [requestErrorMessage],

  props: {
    userId: {
      type: Number,
      default: null,
    },

    date: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isLoadingAudit: false,
      userService: null,
      initExecuted: false,
      audit: null,
      auditTableItems: null,
      auditTableFields: [
        { key: 'field' },
        { key: 'old' },
        { key: 'new' },
      ],
      formatDateTimeDbToView,
      fieldNotAcceptable: [
        'raw',
        'ranking',
        'approval_score',
        'slug',
        'gateway_ref',
        'gateway_type',
        'remember_token',
        'login_at',
        'smarties_disabled_at',
        'turo_at',
        'smarties_at',
        'created_at',
        'updated_at',
        'password',
      ],
    }
  },

  created() {
    this.userService = new UserService()

    this.userData = JSON.parse(localStorage.getItem('userData'))

    this.getAudit()
  },

  methods: {
    linkGen() {
      return '#'
    },

    showEmail(id) {
      this.isLoadingEmail = true

      this.emailService.getEmail(id).then(response => {
        this.isLoadingEmail = false
        this.email = response.data.data.email
      })

      this.$refs['modal-email'].show()
    },

    init() {
      if (!this.initExecuted) {
        this.getAudit()
        this.initExecuted = true
      }
    },

    getAudit() {
      this.isLoadingAudit = true
      this.userService.getAudits(this.userId, this.date).then(response => {
        this.audits = response.data.data.audits
        this.auditTableItems = []

        this.audits.forEach(audit => {
          const fields = [...new Set([...Object.keys(audit.new_values), ...Object.keys(audit.old_values)])].filter(f => !this.fieldNotAcceptable.includes(f))
          let newLine = fields.map(field => ({
            field,
            type: audit.auditable_type.replace('App\\Core\\Entities\\User\\', '').replace('\\', '-').toLowerCase(),
            old: this.getValue(audit.old_values, field),
            new: this.getValue(audit.new_values, field),
          }))
          newLine = newLine.filter(f => (typeof f.new === 'object' || f.new !== '' || typeof f.old === 'object' || f.old !== ''))
          newLine = newLine.filter(f => (!((f.type === 'user' && f.field === 'company_name') || (f.type === 'user' && f.field === 'position_in_company'))))

          if (newLine.length > 0) {
            this.auditTableItems = [...this.auditTableItems, ...newLine]
          }
        })

        this.isLoadingAudit = false
      })
    },

    getValue(auditFields, field) {
      if (auditFields[field]) {
        if (typeof auditFields[field] === 'object') {
          return auditFields[field]
        }

        try {
          if (JSON.parse(auditFields[field])) {
            return JSON.parse(auditFields[field])
          }

          return auditFields[field] !== 'null' ? auditFields[field] : ''
        } catch (e) {
          return auditFields[field] !== 'null' ? auditFields[field] : ''
        }
      }

      if (field === 'preferred_segments' || field === 'preferred_zones') {
        return []
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
  .user-list-audits{
    .pills div div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        margin-bottom: 5px;
      }
    }

    .b-table{
      border: 1px solid #72e3a4!important;

      td{
        padding: 0.8rem 1rem;
      }

      thead tr th {
        background-color: #72e3a4!important;
      }
    }
  }
</style>
